<template>
  <div>
    <div class="container">
      <h1 class="mr-sm-4 header-tablepage mb-3">Setting</h1>
      <div v-for="(form, index) of autoReportSetting" :key="form.autoreport_id">
        <div class="title-tabs content-between">
          <b-form-checkbox
            :id="'checkbox-' + form.autoreport_name"
            v-model="form.active"
            :name="'checkbox-' + form.autoreport_name"
            :value="1"
            :unchecked-value="0"
          >
            {{ form.autoreport_name }}
          </b-form-checkbox>
          <div
            class="cursor-pointer"
            @click="templateSetting(form.autoreport_id)"
            v-if="form.autoreport_id != 3"
          >
            <u>Setting</u>
          </div>
        </div>
        <div class="bg-white text-black py-3 px-4">
          <b-row>
            <b-col md="6">
              <InputSelect
                title="Day"
                name="Day"
                isRequired
                v-model="form.day_id"
                v-bind:options="options.setting.days"
                valueField="dayId"
                textField="dayName"
                :v="$v.autoReportSetting.$each.$iter[index].day_id"
                :isValidate="
                  $v.autoReportSetting.$each.$iter[index].day_id.$error
                "
              >
                <template v-slot:option-first>
                  <b-form-select-option value="0" disabled
                    >-- Select Days --</b-form-select-option
                  >
                  <!-- <b-form-select-option :value="null">All</b-form-select-option> -->
                </template>
              </InputSelect>
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="Time"
                placeholder=""
                type="time"
                isRequired
                v-model="form.time"
                name="times"
                :v="$v.autoReportSetting.$each.$iter[index].time"
                :isValidate="
                  $v.autoReportSetting.$each.$iter[index].time.$error
                "
              />
            </b-col>
            <b-col md="6">
              <InputSelect
                title="Formats"
                name="formats"
                isRequired
                v-model="form.format_id"
                v-bind:options="options.setting.formats"
                valueField="formatId"
                textField="formatName"
                disabled
                :v="$v.autoReportSetting.$each.$iter[index].format_id"
                :isValidate="
                  $v.autoReportSetting.$each.$iter[index].format_id.$error
                "
              >
                <template v-slot:option-first>
                  <b-form-select-option value="0" disabled
                    >-- Select Format --</b-form-select-option
                  >
                  <!-- <b-form-select-option :value="null">All</b-form-select-option> -->
                </template>
              </InputSelect>
            </b-col>
            <b-col md="6">
              <InputText
                type="text"
                textFloat="Email"
                isRequired
                placeholder="Please Input Email"
                v-model="form.emails"
                name="email"
                :v="$v.autoReportSetting.$each.$iter[index].emails"
                :isValidate="
                  $v.autoReportSetting.$each.$iter[index].emails.$error
                "
              />
            </b-col>
            <b-col md="6" v-if="form.autoreport_id == 3">
              <InputText
                textFloat="Remainder Day"
                placeholder="30"
                type="number"
                v-model="form.reminder_day"
                name="reminder_day"
                @blur="(val) => onChatTextEmpty(val, index)"
            /></b-col>
          </b-row>
        </div>
      </div>
      <TemplateSetting ref="template-setting" />
    </div>

    <FooterAction routePath="/setting" @submit="submit" />
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import TemplateSetting from "./template";
export default {
  components: {
    TemplateSetting,
  },
  data() {
    return {
      options: {
        setting: {
          days: [],
          formats: [],
        },
      },
      status: true,
      form: {
        auto_report_day_id: 0,
        time: "",
        auto_report_format_id: 0,
        emails: [],
      },
      autoReportSetting: [],
    };
  },
  validations: {
    autoReportSetting: {
      $each: {
        autoreport_name: { required },
        time: { required },
        day_id: { required },
        format_id: { required },
        emails: { required, email },
      },
    },
  },
  created() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      this.$bus.$emit("showLoading");
      const res = await this.axios(`/AutoReport/setting_auto_report`);

      this.options = res.data.detail;
      this.autoReportSetting = res.data.detail.autoReportSetting;
      this.$bus.$emit("hideLoading");
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$error) return;
      let payload = JSON.parse(JSON.stringify(this.autoReportSetting));
      for (const f of payload) {
        if (typeof f.emails == "string") {
          f.emails = [f.emails];
        }
      }

      this.$bus.$emit("showLoading");
      const res = await this.axios.post(`/AutoReport/update_setting`, payload);
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert().then(() => {
          this.$router.push("/setting");
        });
      } else {
        this.errorAlert(res.data.message);
      }
    },
    templateSetting(id) {
      this.$refs["template-setting"].show(id);
    },
    onChatTextEmpty(val, index) {
     
      if (!val.target.value) this.autoReportSetting[index].reminder_day = 30;
    },
  },
};
</script>

<style></style>
